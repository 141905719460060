.product-list {
  list-style-type: none;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 3rem;
}

.product-list__item {
  border-radius: 4px;
  box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.4);
}

.product-list__item-content {
  padding: 2rem;
}

.product-list__item-title {
  font-size: 2rem;
}
